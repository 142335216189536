import { Suspense, lazy, useRef } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

const Layout = lazy(() => import("./components/Layout"));
const Home = lazy(() => import("./pages/Home"));
const SubscriptionTerms = lazy(() => import("./pages/SubscriptionTerms"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));

export default function Router() {
  const renderLoader = () => <div>Loading...</div>;
  const containerRef = useRef(null)

  return (
    <Suspense fallback={renderLoader()}>
      <Routes>
        <Route path="/" element={<Layout containerRef={containerRef}/>}>
          <Route index element={<Home containerRef={containerRef}/>} />
          <Route path="/terms" element={<SubscriptionTerms containerRef={containerRef}/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy containerRef={containerRef}/>} />
          <Route path="/policy" element={<Navigate replace to="/privacy-policy" />}/>
          <Route path="/privacy-terms" element={<Navigate replace to="/terms" />}/>
        </Route>
      </Routes>
    </Suspense>
  );
}
