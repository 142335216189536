import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import {HeaderProvider} from "./context/headerContext";
import {QrCodeProvider} from "./context/qrCodeContext";
import {SectionProvider} from "./context/currentSectionContext";

import App from './App';
import "./assets/styles/index.scss";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HeaderProvider>
        <SectionProvider>
          <QrCodeProvider>
            <App />
          </QrCodeProvider>
        </SectionProvider>
      </HeaderProvider>
    </BrowserRouter>
  </React.StrictMode>
);

