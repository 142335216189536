import {createContext, useState} from "react";

let HeaderContext;
let { Provider } = (HeaderContext = createContext());

let HeaderProvider = ({ children }) => {
  const [isHome, setIsHome] = useState(true);
  const [isOnHeaderClick, setIsOnHeaderClick] = useState(false);

  return (
    <Provider value={{ isHome, setIsHome, isOnHeaderClick, setIsOnHeaderClick, HeaderContext }}>
      {children}
    </Provider>
  );
};

export { HeaderContext, HeaderProvider };
