import {createContext, useState} from "react";

let SectionContext;
let { Provider } = (SectionContext = createContext());

let SectionProvider = ({ children }) => {
  const [activeSections, setActiveSections] = useState(["home"]);
  const [secondSection, setSecondSection] = useState("goals");
  const [targetSection, setTargetSection] = useState(null);

  return (
    <Provider value={{ activeSections, setActiveSections, targetSection, setTargetSection, secondSection, setSecondSection, SectionContext }}>
      {children}
    </Provider>
  );
};

export { SectionContext, SectionProvider };
