import {createContext, useState} from "react";

let QrCodeContext;
let { Provider } = (QrCodeContext = createContext());

let QrCodeProvider = ({ children }) => {
  const [qrCode, setQrCode] = useState(true);

  return (
    <Provider value={{ qrCode, setQrCode, QrCodeContext }}>
      {children}
    </Provider>
  );
};

export { QrCodeContext, QrCodeProvider };
